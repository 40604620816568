import { createReducer } from '@reduxjs/toolkit';
import { InitialAsyncState, asyncCaseCreator } from '../../redux/helper';
import { EReduxAsyncState } from '../../types/redux';
import { CommonActions } from '../../redux/actions';
import { IContentfulLayout } from '../../types/contentful';

interface ICommonState {
  defaultLayout: InitialAsyncState<IContentfulLayout>;
}

const defaultCommonState: ICommonState = {
  defaultLayout: { status: EReduxAsyncState.idle },
};

export const commonReducer = createReducer(defaultCommonState, builder => {
  asyncCaseCreator(CommonActions.getDefaultLayout, 'defaultLayout', builder);
});

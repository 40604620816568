import { Modal } from 'antd';
import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Select,
  TimePicker,
  FormInstance,
} from 'antd';
import { ClearIcon, TaskIcon } from '../../Icons';
import dayjs from 'dayjs';
import { IStoreBopisSettings } from '../../../types/mongodb';
import { IStore } from '../../../types';
import { useAppSelector } from '../../../redux/hooks';
import { selectCompanySettings } from '../../../redux/selectors/companySelector';
import {
  BopisSettingsEarliestPickupDay,
  BopisSettingsPickupBy,
  BopisSettingsEarliestPickupTimeFormat,
  BopisSettingsPickupSlotDuration,
  BopisSettingsUsuallyReadyIn,
  EBopisSettingsPickupBy,
} from '../../../types/mongodb';

interface StoreBopisSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onCancel?: (form: FormInstance<IStoreBopisSettingsFormData>) => void;
  onApply?: (values: IStoreBopisSettingsFormData) => void;
  loading?: boolean;
  store: IStore;
}

export interface IStoreBopisSettingsFormData
  extends Omit<IStoreBopisSettings, 'bopisEndDate' | 'bopisBeginDate' | 'earliestPickupTime'> {
  bopisEndDate?: dayjs.Dayjs;
  bopisBeginDate?: dayjs.Dayjs;
  earliestPickupTime?: dayjs.Dayjs;
}

function StoreBopisSettingsConfigModal(props: StoreBopisSettingsModalProps) {
  const { isOpen, onClose, store, onApply, onCancel, loading } = props;

  const companySettings = useAppSelector(selectCompanySettings);
  const [form] = Form.useForm<IStoreBopisSettingsFormData>();
  const formPickupByValue = Form.useWatch('pickupBy', form);
  const formBopisEndDate = Form.useWatch('bopisEndDate', form);
  const formBopisBeginDate = Form.useWatch('bopisBeginDate', form);
  const makeItemUnavailableWhenInventoryBelow = Form.useWatch(
    'makeItemUnavailableWhenInventoryBelow',
    form,
  );
  const minOrderValue = Form.useWatch('minOrderValue', form);
  const maxOrderValue = Form.useWatch('maxOrderValue', form);
  const minCustomerAge = Form.useWatch('minCustomerAge', form);
  const markInventoryLowBelow = Form.useWatch('markInventoryLowBelow', form);
  const initialFormValues = {
    ...store.bopisSettings,
    earliestPickupTime: store.bopisSettings?.earliestPickupTime
      ? dayjs(store.bopisSettings?.earliestPickupTime, BopisSettingsEarliestPickupTimeFormat)
      : undefined,
    bopisBeginDate: store.bopisSettings?.bopisBeginDate
      ? dayjs(store.bopisSettings?.bopisBeginDate)
      : undefined,
    bopisEndDate: store.bopisSettings?.bopisEndDate
      ? dayjs(store.bopisSettings?.bopisEndDate)
      : undefined,
  };

  const handleCancelClick = () => {
    if (!loading) {
      onCancel?.(form);
      onClose();
    }
  };

  const handelFormFinish = (values: IStoreBopisSettingsFormData) => {
    onApply?.(values);
  };
  const handelApplyClick = () => {
    form.submit();
  };

  return (
    <Modal
      title="Setup Store Config"
      open={isOpen}
      onCancel={handleCancelClick}
      onOk={handelApplyClick}
      cancelText="Cancel"
      cancelButtonProps={{ shape: 'round', icon: <ClearIcon />, disabled: loading }}
      okButtonProps={{
        shape: 'round',
        icon: <TaskIcon />,
        type: 'primary',
        loading: loading,
      }}
      okText="Apply"
      centered
      width="50%"
      maskClosable={!loading}
    >
      <div className="pt-4 border-t">
        <Form<IStoreBopisSettingsFormData>
          layout="vertical"
          form={form}
          onFinish={handelFormFinish}
          disabled={loading}
          initialValues={initialFormValues}
        >
          <Row gutter={[16, 8]}>
            <Col>
              <Form.Item label="Begin Date" name={'bopisBeginDate'}>
                <DatePicker
                  minDate={
                    companySettings?.bopisBeginDate &&
                    dayjs(companySettings?.bopisBeginDate).isAfter(dayjs())
                      ? dayjs(companySettings?.bopisBeginDate)
                      : dayjs()
                  }
                  maxDate={formBopisEndDate ? formBopisEndDate.subtract(1, 'day') : undefined}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="End Date" name={'bopisEndDate'}>
                <DatePicker
                  minDate={
                    formBopisBeginDate ? formBopisBeginDate.add(1, 'day') : dayjs().add(1, 'day')
                  }
                  // maxDate={
                  //   companySettings?.bopisEndDate ? dayjs(companySettings?.bopisEndDate) : undefined
                  // }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col>
              <Form.Item label="Pickup by Schedule / Earliest" name="pickupBy">
                <Select popupMatchSelectWidth={false} allowClear>
                  {BopisSettingsPickupBy.map(option => (
                    <Select.Option value={option}>{option}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Usually Ready In" name="usuallyReadyIn">
                <Select popupMatchSelectWidth={false} allowClear>
                  {BopisSettingsUsuallyReadyIn.map(value => (
                    <Select.Option key={value} value={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {formPickupByValue === EBopisSettingsPickupBy.Enum['When Ready'] ? (
              <></>
            ) : (
              <>
                <Col>
                  <Form.Item label="Earliest Pickup Day" name="earliestPickupDay">
                    <Select popupMatchSelectWidth={false} allowClear>
                      {BopisSettingsEarliestPickupDay.map(value => (
                        <Select.Option key={value} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Earliest Pickup Time" name="earliestPickupTime">
                    <TimePicker use12Hours format={BopisSettingsEarliestPickupTimeFormat} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Pickup Slot Duration" name="pickupSlotDuration">
                    <Select popupMatchSelectWidth={false} allowClear>
                      {BopisSettingsPickupSlotDuration.map(value => (
                        <Select.Option key={value} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Row gutter={[16, 8]}>
            <Col>
              <Form.Item label="Min Order Value" name="minOrderValue">
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Max Order Value" name="maxOrderValue">
                <InputNumber min={minOrderValue ?? 0} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col>
              <Form.Item label="Min Customer age" name="minCustomerAge">
                <InputNumber min={minCustomerAge ?? 0} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col>
              <Form.Item label="Mark inventory low below" name="markInventoryLowBelow">
                <InputNumber min={makeItemUnavailableWhenInventoryBelow ?? 0} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Make Item Unavailable when inventory below"
                name="makeItemUnavailableWhenInventoryBelow"
              >
                <InputNumber min={0} max={markInventoryLowBelow} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default StoreBopisSettingsConfigModal;

import { ThemeConfig, theme } from 'antd';
import { IContentfulTheme } from '../types/contentful';
export const getThemeConfig = (contentfulTheme?: IContentfulTheme): ThemeConfig => {
  const defaultToken = theme.getDesignToken();
  return {
    token: {
      colorBgBase: contentfulTheme?.backgroundPrimary || '#141414',
      colorError: contentfulTheme?.errorColor || '#FF4040',
      colorInfo: contentfulTheme?.buttonPrimary || '#985CA6',
      colorLink: contentfulTheme?.buttonPrimary || '#985CA6',
      colorPrimary: contentfulTheme?.buttonPrimary || '#985CA6',
      colorTextSecondary: contentfulTheme?.textSecondary,
      colorTextPlaceholder: contentfulTheme?.inputTextColor,
      colorSuccess: '#198038',
      colorTextBase: contentfulTheme?.textPrimary || '#FFFFFF',
      colorWarning: contentfulTheme?.warningColor || '#FF9700',
      fontSize: 16,
      fontSizeSM: 14,
      colorBgContainer: contentfulTheme?.backgroundSecondary || '#282128',
      colorBgElevated: contentfulTheme?.backgroundPrimary || '#141414',
      fontFamily: contentfulTheme?.fontFamily || defaultToken.fontFamily,
    },
    components: {
      Select: {
        // optionFontSize: 18,
        optionPadding: '8px 10px',
        colorText: contentfulTheme?.inputTextColor || contentfulTheme?.textPrimary,
        optionSelectedBg: contentfulTheme?.buttonPrimary,
      },
      Input: {
        colorText: contentfulTheme?.inputTextColor || contentfulTheme?.textPrimary,
      },
      Dropdown: {
        colorText: contentfulTheme?.inputTextColor || contentfulTheme?.textPrimary,
      },
      InputNumber: {
        colorText: contentfulTheme?.inputTextColor || contentfulTheme?.textPrimary,
      },
      Drawer: {
        colorBgElevated: contentfulTheme?.backgroundPrimary || '#141414',
      },
      Card: {
        colorBorder: contentfulTheme?.buttonPrimary || '#985CA6',
        colorBorderSecondary: contentfulTheme?.buttonPrimary || '#985CA6',
      },
      DatePicker: {
        activeBorderColor: contentfulTheme?.buttonPrimary || '#985CA6',
        colorText: contentfulTheme?.inputTextColor || contentfulTheme?.textPrimary,
      },
      Modal: {
        contentBg: contentfulTheme?.backgroundSecondary || '#282128',
        headerBg: contentfulTheme?.backgroundSecondary || '#282128',
      },
      Button: {
        primaryColor: contentfulTheme?.textPrimary,
        dangerColor: contentfulTheme?.errorColor,
        controlItemBgActiveHover: contentfulTheme?.textPrimary,
      },
      Tooltip: {
        colorBgSpotlight: contentfulTheme?.buttonPrimary || '#985CA6',
        colorTextLightSolid: contentfulTheme?.textPrimary || '#fff',
      },
      Tag: {
        defaultColor: contentfulTheme?.textPrimary || '#FFFFFF',
      },
    },
    algorithm: theme.darkAlgorithm,
  };
};

export const printThemeConfig: ThemeConfig = {
  token: {
    colorBgBase: '#FFFFFF',
    colorError: '#FF4040',
    colorInfo: '#985CA6',
    colorLink: '#985CA6',
    colorPrimary: '#985CA6',
    colorSuccess: '#198038',
    colorTextBase: '#000000',
    colorWarning: '#FF9700',
    fontSize: 16,
    fontSizeSM: 14,
    colorBgContainer: '#FFFFFF',
    colorBgLayout: '#FFFFFF',
    colorBgBlur: '#FFFFFF',
  },
  components: {
    Select: {
      optionPadding: '8px 10px',
    },
    Dropdown: {},
    Modal: {
      contentBg: '#FFFFFF',
      headerBg: '#FFFFFF',
    },
  },
  algorithm: theme.darkAlgorithm,
};

import { createReducer } from '@reduxjs/toolkit';
import { InitialAsyncState, asyncCaseCreator } from '../helper';
import { CommonActions, UserActions } from '../actions';
import { IMongodbUser } from '../../types/mongodb';
import { EReduxAsyncState } from '../../types/redux';

interface IUserState {
  users: InitialAsyncState<IMongodbUser[]>;
  syncUser: InitialAsyncState;
}

const defaultUserState: IUserState = {
  users: { status: EReduxAsyncState.idle, data: [] },
  syncUser: { status: EReduxAsyncState.idle },
};

export const userReducer = createReducer(defaultUserState, builder => {
  asyncCaseCreator(UserActions.getUsers, 'getAdminAndFulfillmentUsers', builder);
  asyncCaseCreator(UserActions.updateUser, 'updateUser', builder);
  asyncCaseCreator(UserActions.syncUsers, 'syncUser', builder);

  builder
    .addCase(UserActions.setUsers, (state, action) => {
      state.users.data = action.payload;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultUserState;
    });
});

import { Button, Space, Typography, FormInstance } from 'antd';
import { SettingsIcon } from '../../Icons';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { IStoreBopisSettings } from '../../../types/mongodb';
import { IStore } from '../../../types';
import { useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectCompanySettings } from '../../../redux/selectors/companySelector';
import { BopisSettingsEarliestPickupTimeFormat } from '../../../types/mongodb';
import StoreBopisSettingsConfigModal from './StoreBopisSettingsConfigModal';
import { StoreConfigCompulsoryProducts } from '../StoreConfigCompulsoryItem';

export interface IStoreBopisSettingsFormData
  extends Omit<IStoreBopisSettings, 'bopisEndDate' | 'bopisBeginDate' | 'earliestPickupTime'> {
  bopisEndDate?: dayjs.Dayjs;
  bopisBeginDate?: dayjs.Dayjs;
  earliestPickupTime?: dayjs.Dayjs;
}
export interface IStoreBopisSettingsProps {
  className?: string;
  onCancel?: (form: FormInstance<IStoreBopisSettingsFormData>) => void;
  onApply?: (values: IStoreBopisSettingsFormData) => void;
  loading?: boolean;
  store: IStore;
  hideCompanyInfo?: boolean;
}

export const StoreBopisSettings = ({
  className,
  onCancel,
  onApply,
  loading,
  store,
  hideCompanyInfo,
}: IStoreBopisSettingsProps) => {
  const companySettings = useAppSelector(selectCompanySettings);

  const initialFormValues = {
    ...store.bopisSettings,
    earliestPickupTime: store.bopisSettings?.earliestPickupTime
      ? dayjs(store.bopisSettings?.earliestPickupTime, BopisSettingsEarliestPickupTimeFormat)
      : undefined,
    bopisBeginDate: store.bopisSettings?.bopisBeginDate
      ? dayjs(store.bopisSettings?.bopisBeginDate)
      : undefined,
    bopisEndDate: store.bopisSettings?.bopisEndDate
      ? dayjs(store.bopisSettings?.bopisEndDate)
      : undefined,
    minOrderValue: store.bopisSettings?.minOrderValue || 0,
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleConfigModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalSubmit = () => {};
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  // useEffect(() => {
  //   console.log('form store', store);
  // }, [store]);
  return (
    <div className={clsx(className)}>
      {!hideCompanyInfo && (
        <div className=" flex flex-wrap justify-between items-center mb-3">
          <Space>
            <Typography.Text>Company BOPIS:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {companySettings?.bopisStatus || ''}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text>Begin Date:</Typography.Text>
            <Typography.Text type="secondary">
              {companySettings?.bopisBeginDate
                ? dayjs(companySettings.bopisBeginDate).format('MM/DD/YYYY')
                : '-'}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text>End Date:</Typography.Text>
            <Typography.Text type="secondary">
              {companySettings?.bopisEndDate
                ? dayjs(companySettings.bopisEndDate).format('MM/DD/YYYY')
                : '-'}
            </Typography.Text>
          </Space>
        </div>
      )}

      <div>
        <div className="flex flex-wrap gap-4 items-center mb-3">
          <Space>
            <Typography.Text>Begin Date:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {initialFormValues?.bopisBeginDate
                ? dayjs(initialFormValues?.bopisBeginDate).format('MM/DD/YYYY')
                : '-'}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text>End Date:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {initialFormValues?.bopisEndDate
                ? dayjs(initialFormValues?.bopisEndDate).format('MM/DD/YYYY')
                : '-'}
            </Typography.Text>
          </Space>
        </div>
        <div className="flex flex-wrap gap-4 items-center mb-3">
          <Space>
            <Typography.Text>Pickup by Schedule / Earliest:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {initialFormValues?.pickupBy || '-'}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text>Usually Ready In:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {initialFormValues?.usuallyReadyIn || '-'}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text>Earliest Pickup Day:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {initialFormValues?.earliestPickupDay || '-'}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text>Earliest Pickup Time:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {initialFormValues.earliestPickupTime
                ? dayjs(initialFormValues.earliestPickupTime).format(
                    BopisSettingsEarliestPickupTimeFormat,
                  )
                : '-'}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text>Pickup Slot Duration:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {initialFormValues?.pickupSlotDuration || '-'}
            </Typography.Text>
          </Space>
        </div>
        <div className="flex flex-wrap gap-4 items-center mb-3">
          <Space>
            <Typography.Text>Min Order Value:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {initialFormValues?.minOrderValue || '-'}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text>Max Order Value:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {initialFormValues?.maxOrderValue || '-'}
            </Typography.Text>
          </Space>
        </div>
        <div className="flex flex-wrap gap-4 items-center mb-3">
          <Space>
            <Typography.Text>Min Customer Age:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {initialFormValues?.minCustomerAge || '-'}
            </Typography.Text>
          </Space>
        </div>
        <div className="flex flex-wrap gap-4 items-center mb-3">
          <Space>
            <Typography.Text>Mark inventory low below:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {initialFormValues?.markInventoryLowBelow || '-'}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text>Make Item Unavailable when inventory below:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {initialFormValues?.makeItemUnavailableWhenInventoryBelow || '-'}
            </Typography.Text>
          </Space>
        </div>
        <div className="flex flex-wrap gap-4 items-center mb-3">
          <Space>
            <Typography.Text>Compulsory Products:</Typography.Text>
            <Typography.Text type="secondary" className="capitalize">
              {store.compulsoryProducts?.length || 0}
            </Typography.Text>
          </Space>
        </div>
      </div>

      <div className=" flex justify-end items-center gap-2">
        <StoreConfigCompulsoryProducts store={store} />
        <Button
          shape="round"
          type="primary"
          icon={<SettingsIcon />}
          className="flex items-center"
          onClick={handleConfigModalOpen}
        >
          Config
        </Button>
      </div>
      <StoreBopisSettingsConfigModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        store={store}
        loading={loading}
        onApply={onApply}
        onCancel={onCancel}
      />
    </div>
  );
};

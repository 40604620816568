import clsx from 'clsx';
import { useAppSelector } from '../../redux/hooks';
import { selectDefaultLayout } from '../../redux/selectors/commonSelector';
import defaultImage from '../../assets/images/defaultImage.png';

export const Image = (
  props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
  const { src } = props;

  const layout = useAppSelector(selectDefaultLayout);

  return (
    <img
      className={clsx(props.className)}
      {...props}
      style={props.style}
      src={src || layout.data?.defaultImage?.url || defaultImage}
      alt={props.alt}
    />
  );
};

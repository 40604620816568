import { put, call, takeLeading, fork } from 'redux-saga/effects';
import { CommonActions } from '../../redux/actions';
import { getDefaultLayout } from '../services/commonService';

export function* getDefaultLayoutSaga(): Generator<unknown, void, any> {
  try {
    yield put(CommonActions.getDefaultLayout.start());
    const layout = yield call(getDefaultLayout);
    yield put(CommonActions.getDefaultLayout.success(layout));
  } catch (error) {
    yield put(CommonActions.getDefaultLayout.error(error as Error));
  }
}

function* getDefaultLayoutListener() {
  yield takeLeading(CommonActions.getDefaultLayout.saga.toString(), getDefaultLayoutSaga);
}

export default function* commonSaga() {
  yield fork(getDefaultLayoutListener);
}

import { useAppSelector } from '../../redux/hooks';
import { selectDefaultLayout } from '../../redux/selectors/commonSelector';

interface LogoProps {
  width?: string | number;
  height?: string | number;
  className?: string;
}

export const PFLogo = ({ width = 132, height = 132, className = '' }: LogoProps) => {
  const layout = useAppSelector(selectDefaultLayout);

  return (
    <div
      className={className}
      style={{
        width,
        height,
      }}
    >
      {layout.data?.logo?.url ? (
        <img
          src={layout.data?.logo?.url}
          alt={layout.data?.organizationName || ''}
          className="h-full w-full"
        />
      ) : null}
    </div>
  );
};

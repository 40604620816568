import { IContentfulLayout } from '../../types/contentful';
import { actionCreator, asyncActionCreator } from '../helper';

enum CommonActionTypes {
  CLEAR_REDUX_STORE = 'COMMON/CLEAR_REDUX_STORE',
  GET_DEFAULT_LAYOUT = 'COMMON/GET_DEFAULT_LAYOUT',
}

export const clearReduxStore = actionCreator(CommonActionTypes.CLEAR_REDUX_STORE);
export const getDefaultLayout = asyncActionCreator<IContentfulLayout>(
  CommonActionTypes.GET_DEFAULT_LAYOUT,
);

import { z } from 'zod';
import {
  EBopisSettingsEarliestPickupDay,
  EBopisSettingsPickupBy,
  EBopisSettingsPickupSlotDuration,
  EBopisSettingsUsuallyReadyIn,
} from './common';

export const StoreBopisSettingsSchema = z.object({
  bopisBeginDate: z.string().optional(),
  bopisEndDate: z.string().optional(),
  pickupBy: EBopisSettingsPickupBy.optional(),
  earliestPickupDay: EBopisSettingsEarliestPickupDay.optional(),
  earliestPickupTime: z.string().optional(),
  pickupSlotDuration: EBopisSettingsPickupSlotDuration.optional(),
  markInventoryLowBelow: z.number().min(0).optional(),
  makeItemUnavailableWhenInventoryBelow: z.number().min(0).optional(),
  usuallyReadyIn: EBopisSettingsUsuallyReadyIn.optional(),
  minOrderValue: z.number().optional(),
  maxOrderValue: z.number().optional(),
  minCustomerAge: z.number().optional(),
});

export type IStoreBopisSettings = z.infer<typeof StoreBopisSettingsSchema>;

export enum EMongodbStoreBopisStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  NoBopis = 'No BOPIS',
}

const StoreCompulsoryProductsSchema = z.object({
  shopifyId: z.string(),
  sku: z.string(),
  itemCode: z.string(),
  minQuantity: z.number(),
});

export const MongodbStoreSchema = z.object({
  _id: z.string(),
  locationCode: z.string(),
  bopisStatus: z.nativeEnum(EMongodbStoreBopisStatus),
  bopisSettings: StoreBopisSettingsSchema.optional(),
  compulsoryProducts: z.array(StoreCompulsoryProductsSchema),
  shopifyId: z.string(),
  createdOn: z.string(),
  serverCreatedOn: z.string(),
  updatedOn: z.string(),
  serverUpdatedOn: z.string(),
  waiverForm: z.any().optional(),
  openOrdersCount: z.number(),
});

export type IMongodbStore = z.infer<typeof MongodbStoreSchema>;

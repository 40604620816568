import { callPageFunction } from '../../utils';

export const getDefaultLayout = async () => {
  try {
    const res = await callPageFunction(`/api/public/contentful/layout`);
    return res.layout;
  } catch (err) {
    console.log('Error while fetching default layout: ', err);
    throw err;
  }
};

import { ValidateUser } from './components';
import { AppRoutes } from './routes';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

import { LicenseManager } from 'ag-grid-enterprise';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { CommonActions } from './redux/actions';
import { selectDefaultLayout } from './redux/selectors/commonSelector';
import { ConfigProvider, Spin } from 'antd';
import { EReduxAsyncState } from './types/redux';
import { getThemeConfig } from './constants';
import { Styles } from './components/Styles';
import { Helmet } from 'react-helmet';

const agGridKey = process.env.REACT_APP_AG_GRID_KEY as string;

LicenseManager.setLicenseKey(agGridKey);

function App() {
  const dispatch = useAppDispatch();
  const [themeConfig, setThemeConfig] = useState(getThemeConfig());

  const layout = useAppSelector(selectDefaultLayout);

  useEffect(() => {
    dispatch(CommonActions.getDefaultLayout.saga());
  }, []);

  useEffect(() => {
    if (
      layout.status !== EReduxAsyncState.idle &&
      layout.status !== EReduxAsyncState.inProgress &&
      layout.data?.theme
    ) {
      const _themeConfig = getThemeConfig(layout.data.theme);
      setThemeConfig(_themeConfig);
    }
  }, [layout]);

  if (layout.status === EReduxAsyncState.inProgress) {
    return <Spin fullscreen size="large" />;
  }

  return (
    <ConfigProvider theme={themeConfig}>
      {layout.data?.favicon?.url && (
        <Helmet>
          <link
            rel="icon"
            type={layout.data.favicon?.contentType}
            href={layout.data.favicon?.url}
          ></link>
        </Helmet>
      )}
      <Styles />
      <ValidateUser>
        <AppRoutes />
      </ValidateUser>
    </ConfigProvider>
  );
}

export default App;

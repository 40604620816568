import { useFetchProducts } from '../../../hooks/Admin';
import { Button, Input, Modal, Space, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { ClearIcon, DeleteIcon, SearchIcon, TaskIcon, PlusIcon, MinusIcon } from '../../Icons';
import { IProduct, IStore } from '../../../types';
import { Virtuoso } from 'react-virtuoso';
import Fuse from 'fuse.js';
import { useDispatch } from 'react-redux';
import { StoreActions } from '../../../redux/actions';
import { useAppSelector } from '../../../redux/hooks';
import { EReduxAsyncState } from '../../../types/redux';
import { ProductCard } from '../ProductCard';
import { logEvent } from '../../../utils/EventLogger';
import { EMongodbEventType } from '../../../types/mongodb';

interface IStoreConfigCompulsoryItemProps {
  store: IStore;
}

export const StoreConfigCompulsoryProducts = (props: IStoreConfigCompulsoryItemProps) => {
  const { store } = props;
  const dispatch = useDispatch();
  const updateStore = useAppSelector(state => state.Store.updateStore);
  const products = useFetchProducts();
  const [openModal, setOpenModal] = useState(false);

  const [addCompulsoryProducts, setAddCompulsoryProducts] = useState<IProduct[]>([]);
  const [removeCompulsoryProducts, setRemoveCompulsoryProducts] = useState<IProduct[]>([]);
  const [compulsoryProducts, setCompulsoryProducts] = useState<IProduct[]>([]);
  const [nonCompulsoryProducts, setNonCompulsoryProducts] = useState<IProduct[]>([]);
  const [isClearList, setIsClearList] = useState(false);
  const [searchResult, setSearchResult] = useState<IProduct[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [compulsoryProductsMinQuantity, setCompulsoryProductsMinQuantity] = useState<
    Map<string, number>
  >(new Map());
  const [updateCompulsoryProductsMinQuantity, setUpdateCompulsoryProductsMinQuantity] = useState<
    { shopifyId: string; minQuantity: number }[]
  >([]);
  const updateStoreInProgress = updateStore.status === EReduxAsyncState.inProgress;

  const currentCompulsoryProductsIds = useMemo(() => {
    return (
      store.compulsoryProducts?.map(items => {
        return items.shopifyId;
      }) || []
    );
  }, [store]);

  const currentCompulsoryProducts = useMemo(() => {
    if (products.data?.length) {
      return products.data.filter(product => currentCompulsoryProductsIds.includes(product.id));
    }

    return [];
  }, [products.data, currentCompulsoryProductsIds]);

  const currentNonCompulsoryProducts = useMemo(() => {
    if (products.data?.length) {
      return products.data.filter(
        product => ![...currentCompulsoryProductsIds].includes(product.id),
      );
    }

    return [];
  }, [products.data, currentCompulsoryProductsIds]);

  const handleRemove = (product: IProduct) => {
    setIsClearList(false);
    if (currentCompulsoryProductsIds.includes(product.id)) {
      setRemoveCompulsoryProducts(prev => [product, ...prev]);
    }

    setNonCompulsoryProducts(prev => [product, ...prev]);
    setAddCompulsoryProducts(prev => prev.filter(value => value.id !== product.id));
    setCompulsoryProducts(prev => prev.filter(value => value.id !== product.id));
  };

  const handleAdd = (product: IProduct) => {
    setIsClearList(false);
    setCompulsoryProducts(prev => [product, ...prev]);
    if (!currentCompulsoryProductsIds.includes(product.id)) {
      setCompulsoryProductsMinQuantity(prev => {
        const newMap = new Map(prev);
        newMap.set(product.id, 1);

        return newMap;
      });
      setAddCompulsoryProducts(prev => [product, ...prev]);
    }
    setRemoveCompulsoryProducts(prev => prev.filter(value => value.id !== product.id));
    setNonCompulsoryProducts(prev => prev.filter(value => value.id !== product.id));

    logEvent({
      type: EMongodbEventType.Enum['bopis.admin.add-product-compulsory-at-store'],
    });
  };
  const handleSearchInputChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleClearListClick = () => {
    setIsClearList(true);
    setAddCompulsoryProducts([]);
    setCompulsoryProducts([]);
    setRemoveCompulsoryProducts([]);
  };

  const handleCancel = (closeModal?: boolean) => {
    setAddCompulsoryProducts([]);
    setRemoveCompulsoryProducts([]);
    setCompulsoryProducts(currentCompulsoryProducts);
    setNonCompulsoryProducts(currentNonCompulsoryProducts);
    setIsClearList(false);
    if (closeModal) {
      setOpenModal(false);
    }
  };

  const handleQuantity = (product: IProduct, quantity: number) => {
    const minQuantity = compulsoryProductsMinQuantity.get(product.id) || 1;
    if (quantity === -1 && minQuantity === 1) {
      setCompulsoryProductsMinQuantity(prev => {
        const newMap = new Map(prev);
        newMap.set(product.id, minQuantity);

        return newMap;
      });
      setUpdateCompulsoryProductsMinQuantity(prev => {
        const newList = prev.filter(item => item.shopifyId !== product.id);
        return [
          ...newList,
          {
            minQuantity: 1,
            shopifyId: product.id,
          },
        ];
      });
    } else if (quantity === -1 && minQuantity > 1) {
      setCompulsoryProductsMinQuantity(prev => {
        const newMap = new Map(prev);
        newMap.set(product.id, minQuantity + quantity);

        return newMap;
      });

      setUpdateCompulsoryProductsMinQuantity(prev => {
        const newList = prev.filter(item => item.shopifyId !== product.id);
        return [
          ...newList,
          {
            minQuantity: minQuantity + quantity,
            shopifyId: product.id,
          },
        ];
      });
    } else if (quantity === 1) {
      setCompulsoryProductsMinQuantity(prev => {
        const newMap = new Map(prev);
        newMap.set(product.id, minQuantity + 1);
        return newMap;
      });

      setUpdateCompulsoryProductsMinQuantity(prev => {
        const newList = prev.filter(item => item.shopifyId !== product.id);
        return [
          ...newList,
          {
            minQuantity: minQuantity + 1,
            shopifyId: product.id,
          },
        ];
      });
    }
  };

  const handleConfirmCompulsoryProductsClick = () => {
    if (!store._id) {
      return;
    }

    dispatch(
      StoreActions.updateStores.saga({
        actionType: 'updateCompulsoryProducts',
        updatePayload: {
          mongodbStoreId: store._id,
          addCompulsoryProducts:
            addCompulsoryProducts?.map(product => ({
              shopifyId: product.id,
              sku: product?.variants?.nodes?.[0]?.sku || '',
              itemCode: product.itemCode?.value || '',
              minQuantity: compulsoryProductsMinQuantity.get(product.id) || 1,
            })) || undefined,
          removeCompulsoryProductsId:
            removeCompulsoryProducts?.map(product => product.id) || undefined,
          updateCompulsoryProductsMinQuantity,
          isClearList,
        },
      }),
    );

    logEvent({
      type: EMongodbEventType.Enum['bopis.admin.update-product-compulsory-at-store'],
      payload: {
        mongodbStoreId: store._id,
        addCompulsoryProducts: addCompulsoryProducts.map(product => ({
          shopifyId: product.id,
          sku: '',
          itemCode: '',
          minQuantity: 1,
        })),
        removeCompulsoryProducts: removeCompulsoryProducts.map(product => ({
          shopifyId: product.id,
          sku: '',
          itemCode: '',
          minQuantity: 1,
        })),
        isClearList,
      },
    });
  };

  useEffect(() => {
    setCompulsoryProducts(currentCompulsoryProducts);
  }, [currentCompulsoryProducts]);

  useEffect(() => {
    setNonCompulsoryProducts(currentNonCompulsoryProducts);
  }, [currentNonCompulsoryProducts]);

  useEffect(() => {
    const fuse = new Fuse(nonCompulsoryProducts, {
      keys: ['title', 'itemCode.value'],
    });
    const timerId = setTimeout(() => {
      const results = fuse.search(searchValue);
      setSearchResult(results.map(result => result.item));
    }, 500);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchValue, nonCompulsoryProducts]);

  useEffect(() => {
    if (updateStore.status === EReduxAsyncState.success) {
      handleCancel(true);
    }
  }, [updateStore.status]);

  useEffect(() => {
    if (store.compulsoryProducts?.length) {
      const newMap = new Map();
      store.compulsoryProducts.forEach(item => {
        newMap.set(item.shopifyId, item.minQuantity);
      });

      setCompulsoryProductsMinQuantity(newMap);
    }
  }, [store]);

  return (
    <>
      <Button onClick={() => setOpenModal(true)} type="primary" shape="round">
        Set Compulsory Products
      </Button>
      <Modal
        width={'100%'}
        centered
        title={`Set compulsory product at ${store?.title || ''}`}
        open={openModal}
        onCancel={() => handleCancel(true)}
        footer={null}
        className=" max-w-5xl h-[80%] overflow-hidden relative [&_>_div:has(.ant-modal-content)]:h-full"
        classNames={{
          wrapper: '',
          content: 'absolute w-full h-full  flex flex-col  overflow-hidden',
          body: ' h-full overflow-hidden w-full',
          header: 'border-0 !border-b !border-solid !border-colorBorder !mb-0 !pb-2 ',
        }}
      >
        <div className="w-full h-full flex flex-col overflow-hidden gap-y-4">
          <div className="w-full h-full flex  flex-row overflow-hidden">
            <div className=" basis-1/2 flex flex-col h-full border-solid border-0 border-r-[0.5px] border-colorBorder overflow-hidden gap-y-2 pt-3">
              <div className="pr-3 flex flex-row  gap-x-2 justify-center items-center">
                <SearchIcon />
                <Input
                  className="flex-auto"
                  value={searchValue}
                  onChange={handleSearchInputChange}
                />
                <Button
                  icon={<ClearIcon />}
                  type="text"
                  shape="circle"
                  onClick={() => setSearchValue('')}
                />
              </div>
              <div className="flex-col flex h-full w-full overflow-hidden gap-y-2">
                <Virtuoso
                  style={{ height: '100%' }}
                  className=" [&_>_div]:pr-3"
                  data={searchResult.length ? searchResult : nonCompulsoryProducts}
                  itemContent={(index, product) => (
                    <ProductCard product={product} className="mb-3">
                      <div className=" flex justify-end items-center">
                        <Button
                          disabled={updateStoreInProgress}
                          type="primary"
                          shape="round"
                          onClick={() => handleAdd(product)}
                        >
                          Add
                        </Button>
                      </div>
                    </ProductCard>
                  )}
                />
              </div>
            </div>
            <div className=" basis-1/2 flex flex-col h-full border-solid border-0 border-l-[0.5px] border-colorBorder overflow-auto gap-y-2 pt-3">
              <div className="pl-3 flex flex-col  gap-y-2">
                <Typography.Text>{`${compulsoryProducts.length} compulsory ${compulsoryProducts.length > 1 ? 'Products' : 'Product'}`}</Typography.Text>

                <Typography.Text type="secondary">
                  {`All selected below products will be automatically add to cart at ${store.title}`}
                </Typography.Text>
              </div>
              <div className=" flex-col flex h-full w-full overflow-hidden gap-y-2">
                {!compulsoryProducts.length && (
                  <div className=" px-3 w-full h-full flex justify-center items-center">
                    <Typography.Text type="warning" className=" w-4/5">
                      There is no product is selected as compulsory items
                    </Typography.Text>
                  </div>
                )}
                <Virtuoso
                  style={{
                    height: '100%',
                    display: !compulsoryProducts.length ? 'none' : undefined,
                  }}
                  className=" [&_>_div]:pl-3"
                  data={compulsoryProducts}
                  itemContent={(index, product) => (
                    <ProductCard product={product} className="mb-3">
                      <div className=" flex justify-start items-center">
                        <Button
                          disabled={updateStoreInProgress}
                          type="primary"
                          shape="round"
                          onClick={() => handleQuantity(product, -1)}
                          icon={<MinusIcon />}
                        />
                        <div className="p-5">{compulsoryProductsMinQuantity.get(product.id)}</div>
                        <Button
                          disabled={updateStoreInProgress}
                          type="primary"
                          shape="round"
                          onClick={() => handleQuantity(product, 1)}
                          icon={<PlusIcon />}
                        />
                      </div>
                      <div className=" flex justify-end items-center">
                        <Button
                          disabled={updateStoreInProgress}
                          type="primary"
                          shape="round"
                          onClick={() => handleRemove(product)}
                        >
                          Remove
                        </Button>
                      </div>
                    </ProductCard>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center ">
            <Space>
              <Button
                className="flex justify-center items-center"
                disabled={updateStoreInProgress}
                icon={<ClearIcon />}
                shape="round"
                onClick={() => handleCancel(true)}
              >
                Cancel
              </Button>
              <Button
                className="flex justify-center items-center"
                disabled={updateStoreInProgress || isClearList}
                type="primary"
                shape="round"
                icon={<DeleteIcon />}
                onClick={handleClearListClick}
              >
                Clear List
              </Button>
              <Button
                className="flex justify-center items-center"
                loading={updateStoreInProgress}
                disabled={updateStoreInProgress}
                type="primary"
                shape="round"
                icon={<TaskIcon />}
                onClick={handleConfirmCompulsoryProductsClick}
              >
                Confirm Compulsory Products
              </Button>
            </Space>
          </div>
        </div>
      </Modal>
    </>
  );
};

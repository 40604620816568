import { IContentfulTheme } from '../../types/contentful';
import { hexToRgb } from '../../utils';
import { theme } from 'antd';
import { getThemeConfig } from '../../constants';
import { useAppSelector } from '../../redux/hooks';
import { selectDefaultLayout } from '../../redux/selectors/commonSelector';
import { Helmet } from 'react-helmet';

const themeColorProp = [
  'backgroundPrimary',
  'backgroundSecondary',
  'textPrimary',
  'textSecondary',
  'buttonPrimary',
  'headerBackgroundColor',
  'footerBackgroundColor',
  'progressBarColor',
  'warningColor',
  'errorColor',
  'inputBackgroundColor',
  'inputTextColor',
];

const themeSizeProps = ['progressBarHeight'];
const themeFontFamilyProps = ['fontFamily'];

const { getDesignToken } = theme;

const generateStylesString = (theme: IContentfulTheme) => {
  const hexToRgbString = (hex: string) => {
    const rgb = hexToRgb(hex);
    return `${rgb?.r} ${rgb?.g} ${rgb?.b}`;
  };

  let css = '';

  const colorToken = getDesignToken(getThemeConfig(theme));

  const cssColorVarList = themeColorProp.reduce((cssArr: string[], k) => {
    if (
      ['headerBackgroundColor', 'footerBackgroundColor'].includes(k) &&
      // @ts-ignore
      (theme[k] || theme['backgroundPrimary'])
    ) {
      cssArr = [
        ...cssArr,
        `--color-${k}:${hexToRgbString(
          // @ts-ignore
          theme[k] || theme['backgroundPrimary'],
        )};`,
      ];
      // @ts-ignore
    } else if (k === 'inputTextColor') {
      // @ts-ignore
      cssArr = [...cssArr, `--color-${k}:${hexToRgbString(theme[k] || theme['textPrimary'])};`];
      // @ts-ignore
    } else if (theme[k]) {
      // @ts-ignore
      cssArr = [...cssArr, `--color-${k}:${hexToRgbString(theme[k])};`];
    }
    return cssArr;
  }, []);

  const cssSizeVarList = themeSizeProps.reduce((cssArr: string[], k) => {
    // @ts-ignore
    if (theme[k]) {
      // @ts-ignore
      cssArr = [...cssArr, `--size-${k}:${theme[k]}px;`];
    }
    return cssArr;
  }, []);

  const cssFontFamilyVarList = themeFontFamilyProps.reduce((cssArr: string[], k) => {
    // @ts-ignore
    if (theme[k]) {
      // @ts-ignore
      cssArr = [...cssArr, `--font-${k}:${theme[k]};`];
    }
    return cssArr;
  }, []);

  const antdCssVars = Object.keys(colorToken).map(tokenKey => {
    // @ts-ignore
    if (typeof colorToken[tokenKey] === 'string' && colorToken[tokenKey].startsWith('#')) {
      // @ts-ignore
      return `--antd-${tokenKey}:${`${hexToRgbString(colorToken[tokenKey])}`};`;
    }
    // @ts-ignore
    return `--antd-${tokenKey}:${colorToken[tokenKey]};`;
  });

  const cssVar = [...cssColorVarList, ...cssSizeVarList, ...cssFontFamilyVarList, ...antdCssVars];

  if (cssVar.length) {
    css = `.custom-theme {
      ${cssVar.join('\n')}
    }
    `;
  }

  return css;
};

export const Styles = () => {
  const layout = useAppSelector(selectDefaultLayout);

  if (!layout.data) {
    return null;
  }

  const styleString = generateStylesString(layout.data?.theme);
  if (!styleString) {
    return null;
  }

  const importString = `@import url(${layout?.data?.theme.fontImportUrl});`;
  console.log('importString: ', !!layout?.data?.theme?.fontImportUrl, importString);
  return (
    <Helmet>
      {layout?.data?.theme?.fontImportUrl && <style>{importString}</style>}
      <style>{styleString}</style>;
    </Helmet>
  );
};

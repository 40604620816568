import { z } from 'zod';
import { StringToDateSchema } from '../common';

export const EMongodbEventType = z.enum([
  // app level
  'bopis.click-admin-app',
  'bopis.click-fulfillment-app',
  'bopis.click-login',
  'bopis.click-logout',

  // fulfillment -->  Home
  'bopis.fulfillment.click-needs-packing-orders', // clicking on needs packing card from fulfillment home container
  'bopis.fulfillment.click-ready-for-pickup-orders',
  'bopis.fulfillment.click-canceled-orders',
  'bopis.fulfillment.click-already-picked-up-orders',
  'bopis.fulfillment.click-notifications',
  'bopis.fulfillment.click-find-orders',
  'bopis.fulfillment.change-store',

  // fulfillment --> common
  'bopis.fulfillment.click-order-details',
  'bopis.fulfillment.print-order',

  // fulfillment --> Needs Packing container
  'bopis.fulfillment.start-packing',
  'bopis.fulfillment.restart-packing',
  'bopis.fulfillment.switch-packer', // click on ok to switch packer
  'bopis.fulfillment.change-products-fulfillment-quantity', // increase or decrease by button or directly enter the number in input field
  'bopis.fulfillment.fetch-products-substitute',
  'bopis.fulfillment.change-substitutes-products-quantity',
  'bopis.fulfillment.mark-order-as-unfulfillable',
  'bopis.fulfillment.mark-order-as-ready-for-pickup',

  // fulfillment --> Ready for pickup container

  'bopis.fulfillment.mark-order-as-picked', // payment-confirmation
  'bopis.fulfillment.handoff-package-to-customer',
  'bopis.fulfillment.switch-order-editing-to-me',

  // mixed up
  'bopis.fulfillment.update-fulfillment-quantity',
  'bopis.fulfillment.click-substitutes',
  'bopis.fulfillment.click-change-substitutes',
  'bopis.fulfillment.click-done',

  // fulfillment --> Canceled order container
  'bopis.fulfillment.confirm-reshelving-order',

  // fulfillment --> Notification
  'bopis.fulfillment.mark-as-read',
  'bopis.fulfillment.load-more-notifications',
  'bopis.fulfillment.click-view-order',

  // fulfillment --> Find orders

  'bopis.fulfillment.search',
  'bopis.fulfillment.clear-search',
  'bopis.fulfillment.remove-searchKey',

  // admin

  'bopis.admin.side-menu.click-stores',
  'bopis.admin.side-menu.click-company-settings',
  'bopis.admin.side-menu.click-products',
  'bopis.admin.side-menu.click-users',

  'bopis.admin.company-settings.click-apply',
  'bopis.admin.company-settings.click-cancel',
  'bopis.admin.enable-bopis',
  'bopis.admin.disable-bopis',
  'bopis.admin.disable-store',
  'bopis.admin.enable-restricted-store',
  'bopis.admin.restrict-store',
  'bopis.admin.confirm-store-restriction',
  'bopis.admin.update-products-bopis-status',
  // admin subs
  'bopis.admin.add-substitutes',
  'bopis.admin.clear-substitutes',
  'bopis.admin.set-default-substitutes',
  'bopis.admin.set-substitutes',
  // stores
  'bopis.admin.start-bopis',
  'bopis.admin.stop-bopis',
  'bopis.admin.activate-bopis',
  'bopis.admin.deactivate-bopis',
  'bopis.admin.view-store-details',
  'bopis.admin.update-store-bopis-setting',
  'bopis.admin.remove-product-bopis-at-store',
  'bopis.admin.add-product-bopis-at-store',
  'bopis.admin.add-product-compulsory-at-store',

  // user
  'bopis.admin.enable-user',
  'bopis.admin.disable-user',
  'bopis.admin.enable-store-packing',
  'bopis.admin.disable-store-packing',
  'bopis.admin.enable-store-pickup',
  'bopis.admin.disable-store-pickup',
  'bopis.admin.enable-store-manager',
  'bopis.admin.disable-store-manager',
  'bopis.admin.remove-user-from-store',
  'bopis.admin.add-user-to-store',
  'bopis.admin.update-product-compulsory-at-store',
]);

export type EMongodbEventType = z.infer<typeof EMongodbEventType>;

const EventSchema = z.object({
  _id: z.string(),
  type: EMongodbEventType,
  payload: z.record(z.string(), z.any()).optional(),
  screenContext: z.string().optional(),
  performedBy: z
    .object({
      email: z.string(),
      name: z.string(),
    })
    .optional(),
  createdOn: StringToDateSchema,
  serverCreatedOn: StringToDateSchema,
});

export type IMongodbEvent = z.infer<typeof EventSchema>;

import * as Realm from 'realm-web';
import { EMongoDbCollection } from './types/mongodb';

const atlasConfig = {
  appId: process.env.REACT_APP_ATLAS_APP_ID,
  groupId: process.env.REACT_APP_ATLAS_GROUP_ID,
  baseUrl: process.env.REACT_APP_ATLAS_BASE_URL,
  dataSourceName: process.env.REACT_APP_ATLAS_DATA_SOURCE_NAME,
  databaseName: process.env.REACT_APP_ATLAS_DATABASE_NAME,
};

export const realmApp = new Realm.App({ id: atlasConfig.appId, baseUrl: atlasConfig.baseUrl });

export function getCollection<T extends { _id: string } = any>(
  collection: `${EMongoDbCollection}`,
) {
  if (!realmApp.currentUser) {
    throw new Error('Login Error');
  }

  const mdb = realmApp.currentUser.mongoClient('mongodb-atlas');
  return mdb.db(atlasConfig.databaseName).collection<T>(collection);
}
